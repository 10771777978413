<template>
  
  <div class="home">
        <div class=" container-fluid p-0 img1">
            <div class="row ">
                <span class="d-flex justify-content-end my-3">
                    <router-link to="sign" class="btn_q_o mx-3 px-5 z-1">Sign up</router-link>
                    <router-link to="login" class="btn_q mx-3 px-5 z-1">Log in</router-link>
                </span>
            </div>
        </div>
        <div class=" container-fluid p-0 ">
            <img src="../assets/Home/Polygon5.png" alt="" class="img2">
                <div class="row row1">
                    <div class="d-flex justify-content-around">
                        <span class=" content_img2_1  mx-3 px-5">
                            <div class="d-grid justify-content-center ">
                                <a href="#about" class="btn_q my-2">About</a>
                                <a href="#services" class="btn_q my-2">Services</a>
                                <a href="#contact" class=" btn_q my-2">Contact</a>
                                <img src="../assets/favicon.png" class="my-5" alt="">
                            </div>
                        </span>
                    <span class=" content_img2_2 mx-1 ">
                        <div class=" text-center justify-content-center">
                            <h3 class="text-center fw-bold">Keep your company mony</h3>
                            <br>
                            <span class="d-flex justify-content-around">
                                <router-link to="/homeuser" class="my-3 btn_q px-5">Get Start</router-link>
                            </span>
                        </div>

                    </span>

                    <span class=" content_img2_3 ">
                        <h1>Safe</h1>
                    <div>
                    
                        <br>
                        <span class=" text-center">
                        <h3 class=" fw-bold">Quick Meeting</h3>
                        
                        <h6 >For new Client</h6>
                        <br>
                        <h3 class="mb-4">start meet with us</h3>
                        <span class=" d-flex justify-content-center">
                            <a href="" class=" text-decoration-none text-white px-5 btn_q">Start meeting <span class=" bi bi-camera-video-fill"></span></a>
                        </span>
                        </span>
                    </div>

                </span>
            </div>
        </div>
    </div>
    <div class=" container-fluid p-0 position-relative contint_buisns" id="about">
        <div class="">
            <img src="../assets/Home/business-people-meet.png" class=" position-absolute" alt="">
            <span class="about row">
                <div class="d-flex justify-content-end ">
                    <span class="w-50">
            <p class=" text-white fw-bold fs-1 d-flex justify-content-start">About Us</p>

            <h1 class="d-flex justify-content-start">Make our client Rise</h1>
            <h5 class="d-flex justify-content-start text-start">This book can be read on small phone screens and monochrome readers, but you'll be happier if things appear in color on a larger screen. I use color as an important teaching tool, so if you're reading in black-and- white, you're sacrificing
                some of the extra teaching value of a full-color ebook.
                <br> <br> Colored elements do show up as a lighter shade on some black-and-white screens, and on those devices the effect isn't entirely lost, but full color is better. As for reading on a larger screen— the book includes more than 2,000
                lines of example code.
                <br><br> Small screens break long lines of code into awkward, arbitrary segments, jumbling the formatting. While still decipherable, the code becomes harder to read. If you don't have a mobile device that's ideal for this book, consider
                installing the free Kindle reading app on your laptop.
            </h5>
        </span>
        </div>
        </span>
    </div>
    </div>

    <!--services--><span class="servics container-fluid row" id="services">
        <p class=" text-white fw-bold fs-1 text-center">Our Services</p>
        <h1 class=" text-center fw-bold">You will be our client after see what we offer</h1>
        <div class="row row-cols-3 my-5">
            <div class="col ">
               
    <div class="bg-white p-3 mx-5 rounded rounded-3 ">

        elements do show up as a lighter shade on some black-and-white screens, and on those devices the effect isn't entirely lost, but full color is better. As for reading on a larger screen— the book includes more than 2,000 lines of example code.
    </div>
    </div>
    <div class="col ">
        <div class="bg-white p-3 mx-5 rounded rounded-3 ">
            elements do show up as a lighter shade on some black-and-white screens, and on those devices the effect isn't entirely lost, but full color is better. As for reading on a larger screen— the book includes more than 2,000 lines of example code.
        </div>
    </div>
    <div class="col  ">
        <div class="bg-white p-3 mx-5 rounded rounded-3 ">
            elements do show up as a lighter shade on some black-and-white screens, and on those devices the effect isn't entirely lost, but full color is better. As for reading on a larger screen— the book includes more than 2,000 lines of example code.
        </div>
    </div>
    </div>
    </span>
    <!--end services-->
    <div class=" container-fluid p-0  img3 position-relative">
        <img src="../assets/Home/p_66.png" alt="" class="">
        <div class="  row tow_young">
            <span class="d-flex">
            <img src="../assets/Home/two-young-businessma.png" alt="" class=" rounded-circle">
            <div class=" ">
                <h3 class=" text-white ">Our Vision</h3>
                
                <h3 class="d-flex justify-content-start text-white">Make our client Rise</h3>
                <h6 class="d-flex justify-content-start text-start text-white col-9">This book can be read on small phone screens and monochrome readers, but you'll be happier if things appear in color on a larger screen. I use color as an important teaching tool, so if you're reading in black-and- white, you're sacrificing
                    some of the extra teaching value of a full-color ebook.
                    <br> <br> Colored elements do show up as a lighter shade on some black-and-white screens, and on those devices the effect isn't entirely lost, but full color is better. As for reading on a larger screen— the book includes more than 2,000
                    lines of example code.
                    <br><br> Small screens break long lines of code into awkward, arbitrary segments, jumbling the formatting. While still decipherable, the code becomes harder to read. If you don't have a mobile device that's ideal for this book, consider
                    installing the free Kindle reading app on your laptop.
                </h6>
            </div>
        </span>
        </div>
    </div>


    <div class=" container-fluid p-0 img4" id="contact">
        <img src="../assets/Home/p_7.png" alt="" class="">
        <div class="message col-3">
            <h1> Leave Message</h1>
            <div class=" form-floating my-5">
                <input type="text" name="email" placeholder="email" id="" class=" form-control">
                <label for="">email</label>
            </div>
            <div class=" form-floating my-5">

                <input type="text" name="message" placeholder="message" id="" class=" form-control">
                <label for="">email</label>
            </div>
            <span class="d-flex justify-content-center"><button class="btn_q px-5" style="padding-inline: 30%;">Submit</button></span>
        </div>
    </div>

    <div class="contaienr">
        <div class="row">

            <div class=" d-flex justify-content-center">
                <span class=" text-center">
                <h1 class=" fw-bold">Make your Busines</h1>
                <h2 class="fw-bold">more Professional</h2>
                <button class="btn_q px-5 position-relative">Get Start</button>
            </span>
            </div>

        </div>
    </div>
    
    <!----footer-->

    <div class=" container-fluid footer" dir="rtl">
        <div class="row row-cols-4">
            <div clas=" col ">
                <img src="../assets/Home/Logo.png" alt="" width="70" class="my-5 mx-5">
            </div>
            <div clas=" col">
                <div class="d-grid">
                    <a href="">About Us</a>
                    <a href="">Meeting</a>
                    <a href="">Services</a>
                    <a href="">Contact</a>
                </div>

            </div>

            <div clas=" col ">
                <div class="d-grid">
                    <a href=""> <span class=" bi bi-facebook mx-2"></span> Facebook</a>
                    <a href=""><span class=" bi bi-instagram mx-2"></span> instagram</a>
                    <a href=""><span class="bi bi-linkedin mx-2"></span>Linked in</a>
                    <a href=""><span class=" bi bi-whatsapp mx-2"></span> WhatsApp</a>
                </div>

            </div>
            <div clas=" col ">
                <div class="d-grid">
                    <a>&nbsp;</a>
                    <a href="">Terms and condition</a>
                    <a href="">Privecy and policies</a>

                    <a>&nbsp;</a>
                </div>

            </div>
        </div>
    </div>

    
    <!----footer-->

    </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
}
</script>

<style scoped>
.home {
    overflow-x: hidden;
    box-shadow: inset 0px 0px 10px rgba(165, 215, 232, 0.6);
    /* Warning: CSS does not support Inner Shadow.*/
    background: #f7f7f7;
    background: linear-gradient(91deg, #f7f7f7 0.00%, #3787a1 100.00%);
    font-family:Arial, Helvetica, sans-serif;
}

.img1 {
    background-image: url("../assets/Home/close-up-glasses-ann.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
}

.img2 {
    margin-top: -60%;
    width: 200%;
    margin-left: -80vw;
    height: 215vh;
}

.row1 {
    overflow-x: hidden;
    margin-top: -70%;
}

.content_img2_1 a {
   
    text-decoration: none;
    border-radius: 40px;
    font-size: 100%;
    padding: 10px;
    padding-inline: 20%;
    text-align: center;
}

.content_img2_1 img {
    width: 10rem;
    height: 8rem;
}

.content_img2_2 {
    margin-top: 10vh;
    padding-left: 10rem;
}

.content_img2_2 h1 {
    word-spacing: 5px;
    font-size: 45px;
    margin-bottom: 25px;
    
}

.content_img2_2 a {
    
    text-decoration: none;
    border-radius: 40px;
    font-size: 100%;
    padding: 3%;
    padding-inline: 20%;
}

.content_img2_3 {
    margin-top: 15vh;
    transform: translateX(0vw);
}

.content_img2_3>h1 {
    font-weight: bold;
    font-size: 70px;
}

.content_img2_3 div {
    padding:1rem;
    box-shadow: inset 0px 0px 10px rgba(165, 215, 232, 0.6);
    /* Warning: CSS does not support Inner Shadow.*/
    background: linear-gradient(91deg, #f7f7f7 0.00%, #3787a1 100.00%);
    opacity: 0.78;
    border-radius: 40px;
    width: 30rem;
    margin-top: 30%;
    transform: translateX(-10rem);
}

.content_img2_3 div span a {
    word-spacing: 5px;
    font-weight: bold;
   
    text-decoration: none;
    border-radius: 40px;
    font-size: 100%;
    padding: 3%;
    padding-inline: 10%;
}

.contint_buisns img {
    margin-left: 10%;
    width: 25rem;
    margin-top: 10%;
}

.about {
    padding-top: 20vh;
    padding-right: 1rem;
}

.about h1 {
    color: #0b2447;
    font-weight: bold;
    font-size: 40px;
    justify-self: end;
}

.servics {
    margin-top: 10vh;
}

.img3 {
    margin-top: 0%;
}

.tow_young {
    margin-top: -92.5rem;
    padding-left: 24%;
}

.tow_young img {
    width: 15rem;
    height: 15rem;
}

.tow_young div {
    margin-left: 10%;
}

.img4 {
    margin-top: 15%;
    margin-left: -2%;
}

.img4>img {
    width: 50rem;
}

.img4 .message {
    margin-top: -45rem;
    margin-left: 5%;
}

.footer {
    background-image: url("../assets/Home/Path1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 28vh;
    margin-top: 23%;
}

.d-grid {
    margin-top: 6vh;
    margin-right: -5vw;
}

.d-grid a {
    color: white;
    text-decoration: none;
    margin-top: 1vh;
    letter-spacing: 1px;
    font-size: 0.8rem;
}

.btn_q {
    box-shadow: inset 0px 0px 49.5px #1a437c;
    /* Warning: CSS does not support Inner Shadow.*/
    background: #a5d7e8;
    color: white;
    text-decoration: none;
    border-radius: 40px;
    font-size: 100%;
    padding: 8px;
    padding-inline: 40px;
    font-weight: bold;
    font-size: 15px;
    border: 1px solid transparent;
    z-index: 1;
}

.btn_q:hover{
    box-shadow: inset 0px 0px 49.5px #12223a;
}

.btn_q_o {
    box-shadow: inset 0px 0px 49.5px #a5d7e8;
    /* Warning: CSS does not support Inner Shadow.*/
    background: #a5d7e8;
    color: white;
    text-decoration: none;
    border-radius: 40px;
    font-size: 100%;
    padding: 8px;
    padding-inline: 40px;
    font-weight: bold;
    font-size: 15px;
    z-index: 1;
}

.btn_q_o:hover{
    box-shadow: inset 0px 0px 49.5px #12223a;
}
</style>