<template>
  <nav>
  </nav>
  <router-view :key="$route.fullPath"/>
</template>

<script>
export default{
  name : 'App',
  provide(){
    return{
      hostname : 'https://qmohasib.com'
    }
  }
}</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
